<template>
  <div class="pb-5 mb-2">
    <div class="d-flex align-items-center mb-4">
      <div class="col-md-6">
        <a
          @click="$router.back()"
          class="text-dark d-flex align-items-center cursor-pointer"
        >
          <span class="d-flex align-items-center">
            <vs-icon icon="chevron_left"></vs-icon>
          </span>
          <span class="text-underline">Back</span>
        </a>
      </div>
      <div class="col-md-6">
        <div class="text-right">
          <button
            class="btn btn-primary btn-site-outline px-3"
            @click="$router.push('/blog')"
          >
            View all blog
          </button>
        </div>
      </div>
    </div>

    <div>
      <h1 class="text-dark font-weight-bold">Create New Blog</h1>

      <div class="row">
        <div class="col-md-12">
          <div class="card siteCard">
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="name" class="text-dark">Name</label>
                    <input
                      type="text"
                      required
                      class="form-control"
                      id="name"
                      placeholder="Blog Title"
                      v-model="blogItem.title"
                    />
                  </div>

                  <div class="form-group">
                    <label for="phone" class="text-dark">Short Summary</label>
                    <textarea
                      class="form-control"
                      id="phone"
                      placeholder="Enter summary"
                      maxlength="100"
                      v-model="blogItem.summary"
                    />
                  </div>

                  <div class="form-group">
                    <label for="image" class="text-dark">Banner Image</label>
                    <input
                      type="file"
                      accept="image/*"
                      required
                      class="form-control"
                      id="image"
                      @change="onFileChange"
                      placeholder="Blog Title"
                    />
                  </div>

                  <div class="total">
                    <div class="modelx"></div>
                    <vs-chips
                      color="rgb(145, 32, 159)"
                      placeholder="Enter Tags"
                      v-model="tags"
                    >
                      <vs-chip
                        :key="tag"
                        @click="remove(chip)"
                        v-for="tag in tags"
                        closable
                      >
                        {{ tag }}
                      </vs-chip>
                    </vs-chips>
                  </div>
                </div>

                <div class="col-md-7">
                  <vue-editor v-model="blogItem.description"></vue-editor>

                  <!-- <editor :api-key="apiKey" v-model="blogItem.description" :init="{
                    height: 300,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | help',
                  }" /> -->
                </div>
              </div>

              <div class="form-group mt-5 text-right">
                <button
                  :disabled="loading"
                  @click="createBlogItem()"
                  class="btn btn-primary btn-site px-5"
                >
                  Add Blog
                  <BtnLoading
                    v-if="loading"
                    class="d-inline-block"
                    height="18"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "../../../components/BtnLoading.vue";
import { VueEditor } from "vue2-quill-editor";
// import Editor from "@tinymce/tinymce-vue";

export default {
  name: "CreateAdmin",
  data() {
    return {
      loading: false,
      blogItem: {
        title: "",
        summary: "",
        description: "",
      },
      avatar: "",
      tags: [],
      // apiKey: "06sucsy70u1b98ajalqu45g14ty5eccl7hz4cctaktn1r3ai",
    };
  },
  components: {
    BtnLoading,
    VueEditor,
  },
  methods: {
    onFileChange(e) {
      this.avatar = e.target.files[0];
    },
    remove(item) {
      this.tags.splice(this.tags.indexOf(item), 1);
    },
    createBlogItem() {
      this.$vs.loading();
      this.loading = true;

      this.blogItem.tags = this.tags;

      let formData = new FormData();
      formData.append("title", this.blogItem.title);
      formData.append("summary", this.blogItem.summary);
      formData.append("description", this.blogItem.description);
      formData.append("avatar", this.avatar);
      for (var i = 0; i < this.blogItem.tags.length; i++) {
        formData.append("tags[]", this.blogItem.tags[i]);
      }

      let fetch = {
        path: `admin/blog/store`,
        data: formData,
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Blog Item created successfully",
            "Success",
            this.$toastPosition
          );

          this.blogItem = {
            title: "",
            summary: "",
            description: "",
          };

          this.tags = [];

          this.loading = false;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.loading = false;
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Blog Item Creation Failed",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Blog Item Creation Failed",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
